import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 45;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>波形アニメーションの呼び方</li>
        <li>
          音声編集ソフトはAudacity、動画編集ソフトはiMovieとDaVinci Resolve
        </li>
        <li>
          <a
            href="https://www.kapwing.com/tools/waveform"
            target="_blank"
            rel="noopener noreferrer"
          >
            音声アニメーションを作る有料サービス
          </a>
        </li>
        <li>
          <a
            href="https://wavesurfer-js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            プログラミングで波形を作るためのライブラリ wavesurfer.js
          </a>
        </li>
        <li>fusionを使って作ることができる</li>
        <li>
          <a
            href="https://www.steakunderwater.com/wesuckless/viewtopic.php?t=3067"
            target="_blank"
            rel="noopener noreferrer"
          >
            音声波形を作れるプラグインReactor
          </a>
        </li>
        <li>Blackmagic Designはソフトを無料にしてハードを買ってもらう戦略</li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=3g1P_2p0MvY"
            target="_blank"
            rel="noopener noreferrer"
          >
            DaVinci Resolveで音声波形を作るチュートリアル
          </a>
        </li>

        <li>mp3ではなくwavファイルでないとできない</li>
        <li>ビットレートは16-bitでなければならない</li>
        <li>fusionが重くてプレビューがカクつく</li>
        <li>書き出しに時間がかかる</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
